
import { defineComponent, ref } from "vue";
import Menubar from "primevue/menubar";
import Button from "primevue/button";

export default defineComponent({
  components: { Menubar, Button },
  setup() {
    const items = ref([
      {
        label: "Home",
        icon: "pi pi-fw pi-home",
        to: { name: "home" },
      },
      {
        label: "Spiele",
        icon: "pi pi-fw pi-desktop",
        to: { name: "games" },
      },
      {
        label: "Social",
        icon: "pi pi-fw pi-globe",
        to: { name: "social" },
      },
    ]);

    return { items };
  },
});
